import React from "react";
import Helmet from 'react-helmet'
import Layout from "../../components/Layout";
import Fade from 'react-reveal/Fade';

const IndexPage = () => (
  <Layout>
    <Helmet title={`Barton Perreira | Michelle Pakla`} />
    <div className="content content__project">
      <div className="content__container">
        <h1>Barton Perreira</h1>
        <div className="project__container">
            <div className="project__intro">
                <h2>Introduction</h2>
                <p>Barton Perreira is a luxury brand that offers high quality eyewear exclusively handmade in Japan.</p>
            </div>
            <Fade bottom>
              <div className="project__section">
              <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="../../img/barton-perreira/barton-project-image-3-500.png 500w,
                          ../../img/barton-perreira/barton-project-image-3-800.png 800w,
                          ../../img/barton-perreira/barton-project-image-3-1200.png 1200w"                  
                  src="../../img/barton-perreira/barton-project-image-3.png"
                  alt="Desktop and mobile comp images" />
              </div>
              <div className="project__section">
                  <h2>Process</h2>
                  <div className="project__table">
                      <span>Role:</span><span>Lead Designer and Front-End Developer</span>
                      <span>Tools:</span><span>Figma</span>
                      <span>Tech:</span><span>CIMcloud CLI</span>
                  </div>
                  <p>Barton Perreira partnered with CIMcloud to create a B2B platform to better serve their 
                    associates and manage their ERP. I was tasked with designing a site that reflected their 
                    established branding, and then integrating that design using the CIMcloud CLI.</p>
              </div>
              <div className="project__section">
              <img className="image"
                  sizes="(min-width: 1024px) 1024px, 100vw"
                  srcset="../../img/barton-perreira/barton-project-image-4-500.png 500w,
                          ../../img/barton-perreira/barton-project-image-4-800.png 800w,
                          ../../img/barton-perreira/barton-project-image-4-1200.png 1200w"                  
                  src="../../img/barton-perreira/barton-project-image-4.png"
                  alt="Product detail page" />
              </div>
            </Fade>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage